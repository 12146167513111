import { useState } from 'react';
import { Typography, Box } from '@mui/material';

// Components
import ClientSummaryWallet from 'components/SoraWallet/ClientSummaryWallet';
import LoadingSkeleton from 'components/Frame/LoadingSkelton';
import TextInput from 'components/Input/TextInput';
import { SecondaryButton } from 'components/Button/Button';

// Our Input Types
import { TEXT } from 'components/Input/Types';

// Our Hooks
import useGetClientWallet from 'hooks/clients/useGetClientWallet';

function ClientSearchViaId() {
	const [clientSearchId, setClientSearchId] = useState('');
	const [selectedClientId, setSelectedClientId] = useState(null);

	const {
		data: clientWalletData,
		isLoading: isGetClientWalletLoading,
		isError: isGetClientWalletError
	} = useGetClientWallet(null, selectedClientId);

	return (
		<>
			<Box sx={{ width: 500, marginBottom: 4 }}>
				<TextInput
					label="Enter a client id to view their wallet data"
					value={clientSearchId}
					onChange={setClientSearchId}
					type={TEXT}
				/>
			</Box>
			<SecondaryButton
				disabled={!clientSearchId}
				onClick={() => {
					setSelectedClientId(clientSearchId);
				}}
				sx={{ marginBottom: 4 }}
			>
				Pull client data
			</SecondaryButton>

			{selectedClientId && clientWalletData && (
				<ClientSummaryWallet clientWalletData={clientWalletData} />
			)}
			{isGetClientWalletLoading && (
				<LoadingSkeleton sx={{ width: '100%' }} />
			)}
			{isGetClientWalletError && (
				<Typography>
					Something went wrong trying to fetch this client&apos;s
					wallet data.
				</Typography>
			)}
		</>
	);
}

export default ClientSearchViaId;
