import { useMemo, useState, useRef } from 'react';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Box,
	Grid,
	Popover,
	Typography,
	IconButton,
	FormControl,
	FormLabel,
	FormControlLabel,
	RadioGroup,
	Radio,
	Paper
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

// Our Components
import AutocompleteTextinput from 'components/Input/AutocompleteTextinput';
import ClientListTable from 'components/DataTable/ClientsPage/ClientListTable';
import ClientSummaryWallet from 'components/SoraWallet/ClientSummaryWallet';
import LoadingSkeleton from 'components/Frame/LoadingSkelton';

// Our Hooks
import useGetAllAdvisors from 'hooks/advisors/useGetAllAdvisors';
import useGetClients from 'hooks/clients/useGetClients';
import useGetClientWallet from 'hooks/clients/useGetClientWallet';

function ClientSearchViaAdvisor() {

	// Client State
	const [selectedClient, setSelectedClient] = useState(null);

	// Filter State
	const [filterBy, setFilterBy] = useState('fullName');

	// Advisor State
	const [selectedAdvisor, setSelectedAdvisor] = useState(null);
	const [advisorSearchName, setAdvisorSearchName] = useState('');
	const [popoverOpen, setPopoverOpen] = useState(false);

	// Accordion State
	const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);

	// Ref for the popover anchor
	const anchorEl = useRef(null);

	const helperText =
		filterBy === 'fullName'
			? 'Select an advisor to view their clients'
			: 'Select an advisor email to view their clients';

	const {
		isLoading: isGetAdvisorsLoading,
		data,
		isSuccess: isGetAdvisorsSuccess
	} = useGetAllAdvisors(filterBy);

	// Hooks fetching Data
	const { data: clients, isLoading } = useGetClients(
		!!selectedAdvisor,
		selectedAdvisor?.id
	);

	const {
		data: clientWalletData,
		isLoading: isGetClientWalletLoading,
		isError: isGetClientWalletError
	} = useGetClientWallet(null, selectedClient?.clientId);

	// Creates a label set for each client. Sets these labels to an array of "items", which is used to populate the options within the client selection dropdown
	const listOfAdvisors = useMemo(() => {
		if (isGetAdvisorsSuccess) {
			const advisorList = data.map((currentAdvisor) => {
				const { advisorId } = currentAdvisor;

				const currentFilterChoice = currentAdvisor[filterBy];

				return {
					name: currentFilterChoice,
					id: advisorId,
					fullName: currentAdvisor.fullName,
					email: currentAdvisor.email
				};
			});

			return advisorList;
		}

		return [];
	}, [data, filterBy, isGetAdvisorsSuccess]);

	const handleCleanUp = () => {
		setAdvisorSearchName('');
		setSelectedAdvisor(null);
		setSelectedClient(null);
	};

	const handleClientSelect = (
		currentSelectedAdvisor,
		updateSelectedAdvisor
	) => {

		updateSelectedAdvisor(currentSelectedAdvisor);
		setSelectedAdvisor(currentSelectedAdvisor);
		setIsAccordionExpanded(true);

		if (selectedClient) {
			setSelectedClient(null);
		}
	};

	const handleClientChange = (incomingValue) => {
		if (incomingValue === '') {
			handleCleanUp();
		}
		setAdvisorSearchName(incomingValue);
	};

	if (isGetAdvisorsLoading) {
		return <Typography>Loading...</Typography>;
	}

	if (isGetAdvisorsSuccess) {
		return (
			<>
				<Box sx={{ width: 500, marginBottom: 4 }}>
					{isGetAdvisorsSuccess && listOfAdvisors.length > 0 && (
						<AutocompleteTextinput
							label="Select an advisor to view their clients"
							forcePopupIcon
							freeSolo={false}
							getOptionLabel={({ name }) => name}
							helperText={helperText}
							handleChange={handleClientChange}
							handleSelect={handleClientSelect}
							value={advisorSearchName}
							optionList={listOfAdvisors}
							withoutFilter={false}
							renderOption={(props, option) => (
								<li {...props} key={option.id}>
									{option.name}
								</li>
							)}
							InputAdornment={
								<IconButton
									ref={anchorEl}
									onClick={() => setPopoverOpen(true)}
								>
									<FilterAltIcon />
								</IconButton>
							}
						/>
					)}
				</Box>

				<Popover
					sx={{ marginLeft: 5 }}
					open={popoverOpen}
					anchorEl={anchorEl.current}
					onClose={() => {
						setPopoverOpen(false);
					}}
				>
					<FormControl sx={{ padding: 2 }}>
						<FormLabel id="demo-radio-buttons-group-label">
							Filter by
						</FormLabel>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							name="radio-buttons-group"
							onChange={(e) => {
								setFilterBy(e.target.value);
								setPopoverOpen(false);
								setSelectedAdvisor(null);
								setAdvisorSearchName('');
							}}
							value={filterBy}
						>
							<FormControlLabel
								value="fullName"
								control={<Radio />}
								label="Filter by advisor full name"
							/>
							<FormControlLabel
								value="email"
								control={<Radio />}
								label="Filter by advisor email"
							/>
						</RadioGroup>
					</FormControl>
				</Popover>

				{isLoading && <LoadingSkeleton sx={{ width: '100%' }} />}

				{clients && clients.length < 1 && (
					<Typography sx={{ display: 'block' }}>
						No clients found
					</Typography>
				)}

				{clients && clients.length > 0 && (
					<Box
						component={Paper}
						elevation={3}
						sx={{
							marginBottom: 4,
							borderRadius: 4,
							overflow: 'hidden',
							padding: 1
						}}
					>
						<Accordion
							elevation={0}
							expanded={isAccordionExpanded}
							onChange={() =>
								setIsAccordionExpanded(!isAccordionExpanded)
							}
							sx={{
								borderRadius: 4
							}}
						>
							<AccordionSummary>
								<Typography
									variant="subtitleGascongne"
									sx={{
										display: 'inline-block'
									}}
								>
									{selectedAdvisor?.fullName}&apos;s Clients
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container>
									<ClientListTable
										clients={clients}
										setSelectedClient={setSelectedClient}
										setIsAccordionExpanded={
											setIsAccordionExpanded
										}
									/>
								</Grid>
							</AccordionDetails>
						</Accordion>
					</Box>
				)}
				{selectedClient && clientWalletData && (
					<ClientSummaryWallet clientWalletData={clientWalletData} />
				)}
				{isGetClientWalletLoading && (
					<LoadingSkeleton sx={{ width: '100%' }} />
				)}
				{isGetClientWalletError && (
					<Typography>
						Something went wrong trying to fetch this client&apos;s
						wallet data.
					</Typography>
				)}
			</>
		);
	}

	return <Typography>Something went wrong.</Typography>;
}

export default ClientSearchViaAdvisor;
