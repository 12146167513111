import { useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';

// Our Components
import ColumnItem from 'components/DataTable/ColumnItem';
import ColumnVerticalHeader from 'components/DataTable/ColumnVerticalHeader';
import DataTable from 'components/DataTable/DataTable';
import SearchClients from 'components/Input/Clients/SearchClients';

function ClientListTable({
	clients,
	setSelectedClient,
	setIsAccordionExpanded
}) {
	const [searchData, setSearchData] = useState('');

	const filteredClientsData = useMemo(
		() =>
			clients
				.map(
					({
						accountId,
						invitedEmail: email,
						invitedName: clientName,
						invitedAccepted,
						clientId,
						status,
						numberOfLoans
					}) => ({
						email,
						clientName,
						invitedAccepted,
						clientId,
						status,
						numberOfLoans,
						id: accountId
					})
				)
				.filter((client) => {
					const fieldsOfInterest = [
						client.clientName,
						client.email
					];

					return fieldsOfInterest.some((fieldValue) =>
						fieldValue.toLowerCase().includes(searchData.toLowerCase())
					);
				}),
		[clients, searchData]
	);

	const clientNameKey = 'clientName';
	const emailKey = 'email';
	const invitedAcceptedKey = 'invitedAccepted';
	const numberOfLoansKey = 'numberOfLoans';
	const statusKey = 'status';
	const clientIdKey = 'clientId';

	const columnsClient = [
		ColumnItem(clientNameKey, 'Client Name', 0.5, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			),
			initialSortDirection: 'desc'
		}),
		ColumnItem(emailKey, 'Email', 1, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(statusKey, 'Status', 0.2, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(clientIdKey, 'Client ID', 0.2, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(invitedAcceptedKey, 'Invite Accepted', 0.2, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(numberOfLoansKey, 'Loans', 0.15, {
			valueFormatter: (params) => {
				const numberOfLoans = params.value;

				if (numberOfLoans || numberOfLoans === 0) return numberOfLoans;

				return 'NA';
			},
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		})
	];

	return (
		<>
			<Grid
				container
				sx={{
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: 2
				}}
			>
				<Typography variant="body1" sx={{ display: 'block' }}>
					Select a client below to view their data
				</Typography>
				<Grid item xs={12} xl={3}>
					<SearchClients
						debounceCallBack={setSearchData}
						sx={{ marginBottom: 2, width: '100%' }}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<DataTable
					columns={columnsClient}
					rows={filteredClientsData}
					rowsPerPageOptions={10}
					handleRowClick={(row) => {
						const selectedClient = row;
						const { status } = selectedClient;

						if (status !== 'PENDING' && status !== 'ONBOARDED') {
							setSelectedClient(row);
							setIsAccordionExpanded(false);
						}
					}}
					withRowColorCheck
				/>
			</Grid>
		</>
	);
}

export default ClientListTable;
