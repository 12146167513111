import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import {
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography
} from '@mui/material';

// Icons
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ShowChartIcon from '@mui/icons-material/ShowChart';

// Routes
import {
	DASHBOARD_ROUTE,
	METRICS_ROUTE,
	RATES_ROUTE,
	ADVISORS_ROUTE,
	DB_TABLES_ROUTE,
	CLIENTS_ROUTE
} from 'routes';

const BASE_URL = process.env.REACT_APP_URL;

const ADMIN_NAV_CONFIG = [
	{
		route: DASHBOARD_ROUTE,
		Icon: DashboardOutlinedIcon,
		navText: 'Dashboard',
		isExternal: false
	},
	{
		route: METRICS_ROUTE,
		Icon: ShowChartIcon,
		navText: 'Metrics',
		isExternal: true
	},
	{
		route: RATES_ROUTE,
		Icon: AttachMoneyIcon,
		navText: 'Rates',
		isExternal: true
	},
	{
		route: ADVISORS_ROUTE,
		Icon: PersonOutlineOutlinedIcon,
		navText: 'Advisors',
		isExternal: false
	},
	{
		route: CLIENTS_ROUTE,
		Icon: PersonOutlineOutlinedIcon,
		navText: 'Clients',
		isExternal: false
	},
	{
		route: DB_TABLES_ROUTE,
		Icon: AnalyticsOutlinedIcon,
		navText: 'DB Tables',
		isExternal: true
	}
];

/**
 *
 * @param {boolean} isMobile
 * @param {Object} colors
 * @returns
 */

const getNavConfig = (isMobile, colors, colorOverride) => {
	const { indigo, white } = colors;

	const hasColorOverride = colorOverride in colors;

	if (hasColorOverride) {
		// If there is a color override, use it
		const finalColor = colors[colorOverride];

		return isMobile
			? { color: finalColor, fontWeight: 100, variant: 'h2Gascogne' }
			: { color: finalColor, fontWeight: 600, variant: 'body2' };
	}

	return isMobile
		? {
				color: colorOverride ?? indigo,
				fontWeight: 100,
				variant: 'h2Gascogne'
			}
		: {
				color: colorOverride ?? white,
				fontWeight: 600,
				variant: 'body2'
			};
};

function DynamicNavMenu({ navMenuOptions, isMobile, colorOverride }) {
	const SoraTheme = useTheme();
	const navigate = useNavigate();

	const { primary } = SoraTheme.palette;

	const navConfig = useMemo(
		() => getNavConfig(isMobile, primary, colorOverride),
		[isMobile]
	);

	const { color, fontWeight, variant } = navConfig;

	return (
		<List>
			{navMenuOptions.map((navOption) => {
				const { route, Icon, navText, isExternal, onClickCallback } =
					navOption;

				return (
					<ListItem
						key={uuidv4()}
						disablePadding
						onClick={() => {
							const isReactRoute = !isExternal;
							if (isReactRoute) {
								navigate(route);
							} else {
								if (onClickCallback) {
									onClickCallback();
									return;
								} else {
									window.location.href = `${BASE_URL}/${route}`;
								}
							}
						}}
					>
						<ListItemButton>
							{!isMobile && (
								<ListItemIcon sx={{ paddingLeft: 2 }}>
									<Icon
										sx={{
											color,
											width: '24px'
										}}
									/>
								</ListItemIcon>
							)}

							<ListItemText>
								<Typography
									variant={variant}
									sx={{
										color,
										fontWeight
									}}
								>
									{navText}
								</Typography>
							</ListItemText>
						</ListItemButton>
					</ListItem>
				);
			})}
		</List>
	);
}

DynamicNavMenu.propTypes = {
	navMenuOptions: PropTypes.arrayOf(
		PropTypes.shape({
			route: PropTypes.string,
			Icon: PropTypes.object,
			navText: PropTypes.string
		})
	),
	isMobile: PropTypes.bool
};

DynamicNavMenu.defaultProps = {
	navMenuOptions: ADMIN_NAV_CONFIG,
	isMobile: false
};

export default DynamicNavMenu;
